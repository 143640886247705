<template>
  <div id="details-container">
    <p class="tangerine-font">Contact Us</p>
    <div id="contact-information">
      <p>Tel: <a href="tel:+6561234567">+65 9329 3737</a></p>
      <p>Email: <a href="mailto:joey@agw.com">enquiries@alpgw.com</a></p>
      <p><a href="https://www.alpgw.com/">www.alpgw.com</a></p>
      <br />
      <p>
        <a
          href="https://www.google.com.sg/maps/place/100+Pasir+Panjang+Rd,+100+Pasir+Panjang,+Singapore+118518/@1.2755102,103.7935858,17z/data=!3m1!4b1!4m5!3m4!1s0x31da1bb8d461dd71:0xb6110132107d53b9!8m2!3d1.2755048!4d103.7957745"
          target="_blank"
          rel="noopener noreferrer"
        >
          <!-- 100 Pasir Panjang #02-08 <br />
          100 Pasir Panjang Road, Singapore 118518 -->
        </a>
      </p>
    </div>
    <br />
    <!-- <contact-map /> -->
  </div>
</template>

<script>
// import ContactMap from "@/components/ContactPage/ContactMap";
export default {
  name: "contact-details",
  components: {
    // ContactMap,
  },
};
</script>

<style lang="scss" scoped>
#details-container {
  color: #272727;
  h1 {
    font-weight: normal;
    font-size: 3rem;
  }

  #contact-information {
    text-align: left;

    a {
      color: #272727;
    }
  }
}
</style>
