<template>
  <section id="contact-container">
    <contact-details />
    <contact-form @submit-result="onSubmissionResult" />
    <submission-result-modal
      :active="isComponentModalActive"
      :isSuccess="submissionResult"
      @close="onModalChange"
    />
  </section>
</template>

<script>
import ContactDetails from "@/components/ContactPage/ContactDetails";
import ContactForm from "@/components/ContactPage/ContactForm";
import SubmissionResultModal from "@/components/ContactPage/SubmissionResultModal";
export default {
  components: {
    ContactDetails,
    ContactForm,
    SubmissionResultModal,
  },
  metaInfo: {
    title: "Contact Us",
  },
  data() {
    return {
      submissionResult: false,
      isComponentModalActive: false,
    };
  },
  methods: {
    onSubmissionResult(res) {
      this.submissionResult = false;
      if (!res.error) {
        this.submissionResult = true;
      }
      this.onModalChange();
    },
    onModalChange() {
      this.isComponentModalActive = !this.isComponentModalActive;
      document.body.classList.toggle("modal-open");
      if (this.isComponentModalActive) {
        document.getElementById("submission-modal").focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#contact-container {
  background-color: #aec99c;
  padding-top: 100px;
  display: flex;
  justify-content: space-evenly;
  div {
    width: 45%;
    @media only screen and (max-width: 1199.98px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1199.98px) {
    flex-direction: column;
  }
}
</style>
